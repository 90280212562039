import React, { Component } from "react";

class Footer extends Component {
  render() {
    const { hasSMBanner = true } = this.props;
    return (
      <footer className={hasSMBanner ? "" : "no-sm-banner"}>
        <div className="flex flex-center footer-content">
          <a href="/mentions-legales">Mentions Légales &nbsp;</a>

          <span>© Selfcity 2019-{new Date().getFullYear()}&nbsp;</span>
          <span>-</span>
          <span>
            <span>From Toulouse with love&nbsp;</span>
            <span role="img" aria-label="Violette de Toulouse">
              🌸
            </span>
          </span>
        </div>
      </footer>
    );
  }
}

export default Footer;
