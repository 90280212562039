export default [
  {
    title: "Emilie S.",
    rating: ["*", "*", "*", "*", "*"],
    subtitle: "Ponctualité, Qualité, Réactivité",
    description: `Suite à une chaudière TRÈS capricieuse nécessitant plusieurs changements de pièce, j'ai à nouveau de l'eau chaude.
    Merci à A****** et L*** pour leur professionnalisme et leur bonne humeur.
    Parfois avoir de la patience, ça vaut vraiment le coup.
    Je recommande chaudement cette entreprise, elle est très sérieuse.
    `,
  },
  {
    title: "Jason D.",
    rating: ["*", "*", "*", "*", "*"],
    subtitle: "Ponctualité, Qualité, Rapport qualité-prix",
    description: `Changement de chauffe eau effectué aujourd'hui par l'équipe de technicien Selfcity. L'équipe est arrivé dans la plage horraire annoncé. De plus, le personnel était sympatique et efficace. Travail très satisfaisant.`,
  },
  {
    title: "Dominique B.",
    rating: ["*", "*", "*", "*", "*"],
    subtitle: "Ponctualité, Professionnalisme",
    description: `Très bon service. Respect des horaires et professionnalisme. A****** a passé plus de 2 h à résoudre  la panne du chauffe-eau bien qu’il soit intervenu après 18h. Il a le soucis de satisfaire le client et en plus il est Très sympathique. A recommander`,
  },
  {
    title: "Ophélie C.",
    rating: ["*", "*", "*", "*", "*"],
    subtitle: "Ponctualité, Qualité, Rapport qualité-prix, Réactivité",
    description: `Tout simplement parfait, L***, l'intervenant de la société, a été soucieux de bien faire .. Donc rien à dire, à part que nous sommes très satisfait, merci pour ce service de qualité !
    `,
  },
  {
    title: "Zinedine O.",
    rating: ["*", "*", "*", "*", "*"],
    subtitle: "Ponctualité, Qualité, Rapport qualité-prix, Réactivité",
    description: `A****** a été super, ainsi que son collègue T******, l'opération a été faites trés rapidement et sans complications, je recommande fortement`,
  },
  {
    title: "Alexia H.",
    rating: ["*", "*", "*", "*", "*"],
    subtitle: "Efficacité, Réactivité, Professionnalisme",
    description: `Société très réactive, j'ai eu un RDV pour l'entretien de ma chaudière dans la semaine et la personne au téléphone a été très agréable. 
    L'intervenant à été très professionnel, efficace et agréable. 
    Leurs prix sont tout à fait corrects.
    Rien à redire, je recommande vivement.
    `,
  },
  {
    title: "Fatima S.",
    rating: ["*", "*", "*", "*", "*"],
    subtitle: "Qualité, Professionnalisme",
    description: `Excellent travail, intervention de 3 plombiers avant et les 3 ont fait un mauvais diagnostic.
    Très bonne expérience avec cette structure , personnel très compétent et agréable , de l’accueil téléphonique au standard jusqu’au technicien.
    `,
  },
  {
    title: "Tony M.",
    rating: ["*", "*", "*", "*", "*"],
    subtitle: "Ponctualité, Qualité, Rapport qualité-prix, Réactivité",
    description: `Très satisfait de cette intervention. A****** a su gérer avec beaucoup de professionnalisme une difficulté imprévue dans le remplacement du système d'évacuation. Et en plus il est très sympathique ! C'est rassurant de constater qu'il existe encore des gens qui aiment leur métier et qui le font bien. Les tarifs sont particulièrement raisonnables. Bref, je recommande fortement cette société. Et je vous souhaite toute la réussite que vous méritez. T. M.`,
  },
  {
    title: "Claire D.",
    rating: ["*", "*", "*", "*", "*"],
    subtitle: "Ponctualité, Rapport qualité-prix, Réactivité",
    description: `L*** a été très pro, très clair dans ses explications du problème... qui a été réglé rapidement.
    Je n'hésiterai pas à refaire appel à SelfCity si besoin.
    `,
  },
  {
    title: "Poub P.",
    rating: ["*", "*", "*", "*"],
    subtitle:
      "Ponctualité, Professionnalisme, Qualité, Rapport qualité-prix, Réactivité",
    description: `Mon problème a été résolu, c’est qu’on attend naturellement d’un dépannage. Mais à ça, rajoutez, le professionnalisme, l’efficacité, le conseil, un suivi des rdv et le sourire et vous avez ce que j’ai eu, une Intervention parfaite. Merci à votre équipe.`,
  },
  {
    title: "Joyce M.",
    rating: ["*", "*", "*", "*", "*"],
    subtitle: "Ponctualité, Professionnalisme, Qualité, Réactivité",
    description: `Un dépannage réactif (rendez vous pris le jour-même) et très efficace : merci de nous avoir permis de renouer avec l'eau chaude ! En plus de montrer un grand professionnalisme et sa passion pour son métier, Anthony nous a partagé de multiples conseils et s'est montré très sympathique ! Je recommande vivement !`,
  },
  {
    title: "Vincent",
    rating: ["*", "*", "*", "*", "*"],
    subtitle: "Ponctualité, Professionnalisme, Qualité, Réactivité",
    description: `Conseil immédiat, sérieux et bienveillant en plein confinement. Classe ++`,
  },
  {
    title: "Nicolas M.",
    rating: ["*", "*", "*", "*", "*"],
    subtitle: "Ponctualité, Qualité, Réactivité",
    description: `Très pros, efficaces, ponctuels, sympas.`,
  },
  {
    title: "Thimothée H.",
    rating: ["*", "*", "*", "*", "*"],
    subtitle: "Ponctualité, Professionnalisme, Réactivité",
    description: `Super agréable, explique bien tout ce qu’il y as à savoir, très rapide.
    Je conseil`,
  },
  {
    title: "Philippe T.",
    rating: ["*", "*", "*", "*", "*"],
    subtitle:
      "Ponctualité, Professionnalisme, Qualité, Rapport qualité-prix, Réactivité",
    description: `Super boulot avec A***** !!! Réactif, précis et sympa ;) Je recommande à 200 %`,
  },
  {
    title: "Jonathan D.",
    rating: ["*", "*", "*", "*", "*"],
    subtitle: "Ponctualité, Qualité, Réactivité",
    description: `Entreprise très réactive et conciliante ! 
    Le technicien (le même pour le devis et pour la réparation) était très professionnel et compétent, je recommande fortement !
    `,
  },
  {
    title: "Lony B.",
    rating: ["*", "*", "*", "*", "*"],
    subtitle: "Ponctualité, Qualité, Réactivité",
    description: `Très bonne équipe, travail de qualité réalisé dans les temps. Je remercie L***et A****** pour leur professionnalisme`,
  },
  {
    title: "Kassay H.",
    rating: ["*", "*", "*", "*", "*"],
    subtitle: "Ponctualité, Qualité, Réactivité",
    description: `Super équipe, des vrais professionnels, je recommande`,
  },
  {
    title: "Lush M.",
    rating: ["*", "*", "*", "*", "*"],
    subtitle: "Qualité, Réactivité",
    description: `Merci à A***** qui a débouché l'évier et changé le kit de sécurité du cumulus en un temps record et de manière très pro!`,
  },
];
