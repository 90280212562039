import React from "react";
import Helmet from "react-helmet";
import ratings from "../../assets/ratings/plombier-toulouse";
import CONSTANTS from "../../utils/constants";
export default (props) => {
  const googleReviewsNumber = CONSTANTS.GOOGLE_REVIEWS.totalReviewCount;

  const ratingsJSON = ratings.map((review) => {
    const { title, rating, description } = review;
    const ratingJSON = {
      "@type": "Review",
      url: "https://g.page/Selfcity/review",
      author: {
        "@type": "Person",
        name: title,
      },
      description: description,
      inLanguage: "fr",
      reviewRating: {
        "@type": "Rating",
        worstRating: 1,
        bestRating: 5,
        ratingValue: rating.length,
      },
    };
    return ratingJSON;
  });

  const schemaOrgJSONLD = {
    "@context": "https://schema.org",
    "@type": "Organization",
    url: "https://www.selfcity.fr",
    name: "Selfcity - Plombier Toulouse",
    telephone: "+33531603043",
    address: {
      "@type": "PostalAddress",
      streetAddress: "2 avenue de Lombez",
      addressLocality: "Toulouse",
      addressRegion: "Occitanie",
      postalCode: "31300",
      addressCountry: "FR",
    },
    // ==
    // The property geo is not recognized by Google for an object of type Organization.
    //
    // geo: {
    //   "@type": "GeoCoordinates",
    //   latitude: 43.5964,
    //   longitude: 1.42245
    // },
    contactPoint: {
      "@type": "ContactPoint",
      telephone: "+33531603043",
      contactType: "Customer service",
    },
    aggregateRating: {
      "@type": "AggregateRating",
      ratingValue: "4.9",
      bestRating: "5",
      worstRating: "1",
      ratingCount: "" + googleReviewsNumber,
    },
    review: ratingsJSON,
  };

  let { title, description, pathname, url, thumbnail, siteMetadata } = {
    ...props,
  };
  title = title || siteMetadata.title;
  description = description || siteMetadata.description;
  url = url || siteMetadata.url;
  const twitter = "https://twitter.com/_selfcity";
  return (
    <Helmet>
      {/* General tags */}
      <meta charSet="utf-8" />
      <title>{title}</title>
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, shrink-to-fit=no"
      />
      <meta name="theme-color" content="#000000" />

      <meta name="description" content={description} />
      <meta name="image" content={thumbnail && url + thumbnail} />

      {/* Schema.org tags */}
      <script type="application/ld+json">
        {JSON.stringify(schemaOrgJSONLD)}
      </script>

      {/* OpenGraph tags */}
      <meta property="og:url" content={pathname ? url + pathname : url} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={thumbnail && url + thumbnail} />
      <meta
        property="og:image:secure_url"
        content={thumbnail && url + thumbnail}
      />
      <meta property="og:locale" content="fr" />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content="Selfcity" />

      {/* Twitter Card tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:creator" content={twitter} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={thumbnail && url + thumbnail} />

      {/* Facebook */}
      <meta
        name="facebook-domain-verification"
        content="1qrddrr0iq21u29flzc4zdmna6dgqf"
      />

      <meta name="robots" content="index, follow" />

      <html lang="fr" />
    </Helmet>
  );
};
