import React, { useState, useContext } from "react";

import { AppContext } from "../context";

import CONSTANTS from "../utils/constants";

import "../styles/header.css";

function TelephoneNumberBlock({ trackingId, phoneNumber = {} }) {
  return (
    <div className="phone-number-block">
      <span id={trackingId} className="num-tel">
        {phoneNumber.default}
      </span>
    </div>
  );
}

export default ({ reference }) => {
  const [state] = useContext(AppContext);
  const [showMenu, setShowMenu] = useState(false);
  const [hideMenu, setHideMenu] = useState(false);
  const { phoneNumber } = state;

  const handleToggleMenu = () => {
    // Use two variables to disable first "hiding" animation
    setShowMenu(!showMenu);
    setHideMenu(showMenu);
  };

  return (
    <header id="home-header" className="home-header">
      <a href="/" className="no-text-decoration logo">
        <img
          className="logo-selfcity"
          src="/img/logo.png"
          width="210px"
          alt="Selfcity"
        />
      </a>
      <ul className="header-links header-links-md">
        <li>
          <a
            href="/blog"
            className={
              reference === CONSTANTS.PAGE_REFERENCES.BLOG ? "active" : ""
            }
          >
            Blog
          </a>
        </li>
        <li>
          <a
            href="/partenaires"
            className={
              reference === CONSTANTS.PAGE_REFERENCES.PARTENAIRES
                ? "active"
                : ""
            }
          >
            Partenaires
          </a>
        </li>
        <li>
          <a
            href="/plombier-toulouse"
            className={
              reference === CONSTANTS.PAGE_REFERENCES.PLOMBIER_TOULOUSE
                ? "active"
                : ""
            }
          >
            Plombier à Toulouse
          </a>
        </li>
        <li className="item-phone-number">
          <img
            src="/img/index/phone-dark-grey.png"
            width="27px"
            height="27px"
            alt="Téléphone"
          />
          <TelephoneNumberBlock
            trackingId="phone-number-header-md"
            phoneNumber={phoneNumber}
          />
        </li>
      </ul>
      <div className="header-links header-links-sm" onClick={handleToggleMenu}>
        <img src="/img/index/open-menu.png" alt="" width="30px" />
      </div>
      <div
        className={
          "header-menu-sm " +
          (showMenu ? " show-menu " : "") +
          (hideMenu ? " hide-menu " : "")
        }
      >
        <button className="close-button" onClick={handleToggleMenu}>
          X
        </button>
        <ul>
          <li>
            <a
              href="#"
              onClick={handleToggleMenu}
              className={
                reference === CONSTANTS.PAGE_REFERENCES.HOME ? "active" : ""
              }
            >
              Accueil
            </a>
          </li>
          <li>
            <a
              href="/blog"
              className={
                reference === CONSTANTS.PAGE_REFERENCES.BLOG ? "active" : ""
              }
            >
              Blog
            </a>
          </li>
          <li>
            <a
              href="/partenaires"
              className={
                reference === CONSTANTS.PAGE_REFERENCES.PARTENAIRES
                  ? "active"
                  : ""
              }
            >
              Partenaires
            </a>
          </li>
          <li>
            <a
              href="/plombier-toulouse"
              className={
                reference === CONSTANTS.PAGE_REFERENCES.PLOMBIER_TOULOUSE
                  ? "active"
                  : ""
              }
            >
              Plombier à Toulouse
            </a>
          </li>
        </ul>
      </div>
    </header>
  );
};
